import React from 'react';
import './App.css';
import MainMenu from "./components/MainMenu/MainMenu";
import {useAtom} from "jotai"
import {pageAtom} from "./state/atoms";
import SubMenu from "./components/SubMenu/SubMenu";

function App() {
    console.log("INIT APP")
    const [page, setPage] = useAtom(pageAtom);
    console.log(page)
    const tg = window.Telegram.WebApp;
    const backButton = tg.BackButton
    tg.ready()
    tg.expand()

    backButton.onClick(() => {
        backButton.hide()
        setPage("main")
    })

    const renderPage = () => {
        switch (page) {
            case "main":
                return <MainMenu/>;
            case "sub":
                return <SubMenu/>;
            default:
                return <SubMenu/>;
        }
    }

    return (
        <div>{renderPage()}</div>

    );
}

export default App;
