import React, {useEffect} from 'react';

const SubMenu = () => {

    const tg = window.Telegram.WebApp;
    const backButton = tg.BackButton
    const mainButton = tg.MainButton
    mainButton.setText("Send Message")
    tg.onEvent("mainButtonClicked", () => {
        tg.sendData(JSON.stringify({text: "Hello World"}))
    })
    // mainButton.onClick(() => {
    //     tg.sendData(JSON.stringify({text: "Hello World"}))
    // })
    mainButton.show()
    backButton.show()
    console.log(mainButton)
    return (
        <div>
            <h1>Send Messages</h1>

        </div>
    );
};

export default SubMenu;