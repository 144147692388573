import React from 'react';
import '../../App.css';
import { useSetAtom } from 'jotai'
import {pageAtom} from "../../state/atoms";
const MainMenu = () => {

    const setPage = useSetAtom(pageAtom)
    const tg = window.Telegram.WebApp;
    const backButton = tg.BackButton
    const hapticFeedback = tg.HapticFeedback
    const WebAppUser = tg.WebAppUser
    console.log("backButton", backButton)
    const user = tg.initDataUnsafe.user
    console.log("user", user)
    console.log("WebAppUser", WebAppUser)
    console.log("tg", tg)
    const popupHandler = (msg) => {
        console.log(msg)
    }
    const sendData = async () => {
        await fetch("/webhook/test", {
            method: "GET",
        })
    }
    return (
        <div>
            <h1>MainMenu</h1>
            <h2>User Data</h2>
            <div className="avatar">
                <img src={user.photo_url} alt="avatar"/>
            </div>
            <div>
                <p>id: {user.id}</p>
                <p>first_name: {user.first_name}</p>
                <p>last_name: {user.last_name}</p>
                <p>language_code: {user.language_code}</p>
            </div>

            <h2>Platform</h2>
            <div>
                <p>platform: {tg.platform}</p>
            </div>
            <h2>Viewport</h2>
            <div>
                <p>height: {parseInt(tg.viewportHeight)}</p>
                <p>stableHeight: {parseInt(tg.viewportStableHeight)}</p>
            </div>

            <h2>Send Messages</h2>
            <button onClick={() => setPage("sub")}>Send Messages</button>
            <h2>Haptic Feedback</h2>
            <h4>impactOccurred</h4>
            <button onClick={() => hapticFeedback.impactOccurred("light")}>light</button>
            <button onClick={() => hapticFeedback.impactOccurred("medium")}>medium</button>
            <button onClick={() => hapticFeedback.impactOccurred("heavy")}>heavy</button>
            <button onClick={() => hapticFeedback.impactOccurred("rigid")}>rigid</button>
            <button onClick={() => hapticFeedback.impactOccurred("soft")}>soft</button>
            <h4>notificationOccurred</h4>
            <button onClick={() => hapticFeedback.notificationOccurred("success")}>success</button>
            <button onClick={() => hapticFeedback.notificationOccurred("error")}>error</button>
            <button onClick={() => hapticFeedback.notificationOccurred("warning")}>warning</button>
            <button onClick={() => hapticFeedback.notificationOccurred("info")}>info</button>
            <h4>selectionChanged</h4>
            <button onClick={() => hapticFeedback.selectionChanged()}>selectionChanged</button>
            <h4>Popup</h4>
            <button onClick={() => tg.showPopup({title: "popup title", message: "popup message"}, popupHandler)}>Show Popup</button>
        </div>
    );
};

export default MainMenu;